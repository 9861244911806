
import { Component, Vue } from "vue-property-decorator";
import pdf from "vue-pdf";

@Component({
  name: "PrivacyPolicy",
  components: {
    // HelloWorld,
    pdf,
  },
})
export default class PrivacyPolicy extends Vue {
  private currentPage: number = 1; // pdf文件页码
  private pageCount: number = 10; // pdf文件总页数
  private src: string = "/docx/Privacy_PolicyMU.pdf"; // pdf文件地址
  private pdf: any;
  private showLoading: boolean = true;
  private loadPdfHandler() {
    this.pdf = pdf.createLoadingTask(this.src);
    this.pdf.promise.then((pdf: any) => {
      this.currentPage = pdf.numPages;
      this.showLoading = false;
    });
  }
  mounted() {
    this.loadPdfHandler();
  }
}
